import api from '@/api/api.ts';
import { useCachedFileData } from '@/hooks/useCachedFileData.ts';
import { Edit2Icon, PlusCircleIcon, RotateCcw, Trash2Icon } from 'lucide-react';
import { Button } from 'primereact/button';
import { Column, ColumnEditorOptions, ColumnEvent } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { FC, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

interface FileTableProps {
  fileData: any;
  fileId: string;
}

const FileTable: FC<FileTableProps> = ({ fileData, fileId }) => {
  const [tableData, setTableData] = useState<any[]>([]);
  const [dynamicColumns, setDynamicColumns] = useState<any[]>([]);
  const [dialogVisible, setDialogVisible] = useState(false);
  const [editingSwitchboard, setEditingSwitchboard] = useState<string | null>(null);
  const [currentSwitchboard, setCurrentSwitchboard] = useState<string>('');
  const [localEditValue, setLocalEditValue] = useState<string>('');
  const [editingCell, setEditingCell] = useState<{ rowIndex: number; field: string } | null>(null);
  const [newPin, setNewPin] = useState({
    signal_name: '',
    signal_type: '',
    connector_name: '',
    line_number: '',
  });

  const { refetch } = useCachedFileData(fileId);

  const headerTemplate = (data: any) => {
    const pinsUnderSwitchboard = tableData.filter((row) => row.switchboard_uid === data.switchboard_uid);
    const hasPins = pinsUnderSwitchboard.some((row) => row.pin_uid !== null);

    return (
      <div className="flex items-center justify-between gap-2">
        {editingSwitchboard === data.switchboard_uid ? (
          <div className="flex items-center gap-2">
            <InputText
              value={localEditValue}
              onChange={(e) => setLocalEditValue(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  handleSwitchboardNameChange(localEditValue, data.switchboard_uid);
                } else if (e.key === 'Escape') {
                  setEditingSwitchboard(null);
                }
              }}
              autoFocus={true}
            />
          </div>
        ) : (
          <div className="flex items-center gap-2">
            <span className="font-bold">{data.switchboard}</span>
            <Button
              icon={<Edit2Icon size={16} />}
              className="p-button-text p-button-rounded text-black hover:text-blue-700 hover:bg-none focus:border-0 focus:shadow-none"
              onClick={() => {
                setEditingSwitchboard(data.switchboard_uid);
                setLocalEditValue(data.switchboard);
              }}
            />
          </div>
        )}
        <div className={'flex items-center gap-1'}>
          <Button
            icon={<Trash2Icon size={20} />}
            className="p-button-text p-button-rounded text-black hover:text-red-700 hover:bg-none focus:border-0 focus:shadow-none"
            onClick={() => handlePinAction('list', data.switchboard_uid)}
            disabled={!hasPins}
          />
          <Button
            icon={<PlusCircleIcon size={20} />}
            className="p-button-text p-button-rounded text-black hover:text-blue-700  hover:bg-none focus:border-0 focus:shadow-none"
            onClick={() => openNewPinDialog(data.switchboard_uid)}
          />
        </div>
      </div>
    );
  };

  const handleSwitchboardNameChange = async (newName: string, switchboardUid: string) => {
    try {
      const response = await api.put(`switchboard/${switchboardUid}`, { identifier_name: newName });
      if (response.status === 200 || response.status === 202) {
        toast.success('Název switchboardu úspěšně změněn.');
        refetch();
      }
    } catch (error: any) {
      if (error?.response?.data?.detail) {
        toast.error(`Error: ${error.response.data.detail}`);
      } else {
        toast.error(`Chyba při změně názvu rozvaděče.`);
      }
    }
    setEditingSwitchboard(null);
  };

  const openNewPinDialog = (switchboard: string) => {
    setCurrentSwitchboard(switchboard);
    setDialogVisible(true);
  };

  const addNewPin = async () => {
    try {
      const response = await api.post(`add/pin/${currentSwitchboard}`, { ...newPin });
      if (response.status === 200 || response.status === 202) {
        toast.success('Nový řádek úspěšně přidán.');
        setDialogVisible(false);
        await refetch();
      }
    } catch (error: any) {
      if (error?.response?.data?.detail) {
        toast.error(`Error: ${error.response.data.detail}`);
      } else {
        toast.error(`Chyba při ukládání řádku.`);
      }
    }
  };

  const switchboardBodyTemplate = () => {
    // Return an empty element to hide the Switchboard value in table rows
    return null;
  };

  const textEditor = (options: ColumnEditorOptions) => {
    return (
      <InputText
        type="text"
        value={options.value}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          if (options.editorCallback && options.value !== e.target.value) {
            options.editorCallback(e.target.value);
          }
        }}
        onKeyDown={(e) => {
          if (e.key === 'Enter' || e.key === 'Escape') {
            if (options.editorCallback && options.value !== (e.target as HTMLInputElement).value) {
              options.editorCallback(e.key === 'Escape' ? options.value : (e.target as HTMLInputElement).value);
            }
            e.preventDefault(); // Prevent the event from bubbling up
          }
        }}
      />
    );
  };

  const onCellEditComplete = (e: ColumnEvent) => {
    const { rowData, newValue, field, originalEvent: event } = e;

    // Only proceed if the new value is different from the original value
    if (newValue?.trim() !== rowData[field]?.trim()) {
      if (newValue?.trim().length > 0) {
        const updatedValue = newValue.trim();

        api
          .put(`pin/${rowData.pin_uid}`, { [field]: updatedValue })
          .then(() => {
            refetch();
          })
          .catch((error) => {
            console.error('Error updating field', error);
            toast.error('Failed to update field');
          });
      } else {
        event.preventDefault();
        toast.warn('Field cannot be empty');
      }
    }

    setEditingCell(null);
  };

  const cellEditor = (options: ColumnEditorOptions) => {
    if (!options.rowData.pin_uid) {
      return null; // Don't show editor for switchboards without pins
    }

    if (editingCell && editingCell.rowIndex === options.rowIndex && editingCell.field === options.field) {
      return textEditor(options);
    }

    return options.value;
  };

  const handlePinAction = async (action: 'list' | 'bin' | 'unbin', data: any) => {
    try {
      switch (action) {
        case 'list': {
          const pinsUnderSwitchboard = tableData.filter((row) => row.switchboard_uid === data);
          if (pinsUnderSwitchboard.length > 0) {
            const pinUids = pinsUnderSwitchboard.map((pin) => pin.pin_uid);
            const endpoint = action === 'list' ? 'bin/pins' : 'unbin/pins';
            const response = await api.post(endpoint, pinUids);
            if (response.status === 202 || response.status === 200) {
              const actionText = action === 'list' ? 'odebrání' : 'přidání';
              toast.success(`Switchboard označen k ${actionText}.`);
              refetch();
            }
          } else {
            toast.info('Žádné řádky nebyly nalezeny.');
          }
          break;
        }

        case 'bin':
        case 'unbin': {
          const endpoint = action === 'bin' ? 'bin/pins' : 'unbin/pins';
          const response = await api.post(endpoint, [data.pin_uid]);
          if (response.status === 202 || response.status === 200) {
            const actionText = action === 'bin' ? 'odebrání' : 'přidání';
            toast.success(`Řádek označen k ${actionText}.`);
            refetch();
          }
          break;
        }

        default:
          throw new Error('Chybná akce');
      }
    } catch (error: any) {
      if (error?.response?.data?.detail) {
        toast.error(`Error: ${error.response.data.detail}`);
      } else {
        toast.error(`Chyba při provádění akce.`);
      }
    }
  };

  const actionBodyTemplate = (rowData: any) => {
    const isEmptySwitchboard = !rowData.pin_uid;

    if (isEmptySwitchboard) {
      // Return null or an empty fragment for switchboards without pins
      return null;
    }

    if (rowData.binned) {
      return (
        <Button
          icon={<RotateCcw size={16} />}
          className="p-button-text p-button-rounded p-button-info  hover:bg-none focus:border-0 focus:shadow-none"
          onClick={() => handlePinAction('unbin', rowData)}
        />
      );
    }
    if (!rowData.binned) {
      return (
        <Button
          icon={<Trash2Icon size={16} />}
          className="p-button-text p-button-rounded p-button-danger  hover:bg-none focus:border-0 focus:shadow-none"
          onClick={() => handlePinAction('bin', rowData)}
        />
      );
    }
    return null;
  };

  useEffect(() => {
    const currentPageData = fileData();
    if (!currentPageData || !currentPageData.switchboards) return;

    const switchboards = currentPageData.switchboards;

    const data: any[] = [];
    const allFields = new Set<string>();

    switchboards.forEach((switchboard: any) => {
      if (switchboard.pins.length === 0) {
        // Add a row for switchboards with no pins
        data.push({
          switchboard: switchboard.identifier.name,
          switchboard_uid: switchboard.identifier.uid,
          pin_uid: null,
          binned: false,
        });
      } else {
        switchboard.pins.forEach((pin: any) => {
          const row: Record<string, string> = {
            switchboard: switchboard.identifier.name,
            switchboard_uid: switchboard.identifier.uid,
            binned: pin.binned,
            pin_uid: pin.uid,
          };

          Object.entries(pin).forEach(([key, value]: [string, any]) => {
            if (key !== 'line_shape' && value?.name) {
              const columnKey = key;
              row[columnKey] = value.name;
              row[`${columnKey}_uid`] = value.uid;
              allFields.add(columnKey);
            }
          });

          data.push(row);
        });
      }
    });

    const fieldHeaderMap: Record<string, string> = {
      signal_name: 'Name',
      signal_type: 'Type',
      connector_name: 'Connector',
      line_number: 'Line',
    };

    const columns = Array.from(allFields).map((field) => ({
      field,
      header: fieldHeaderMap[field] || field,
    }));

    setTableData(data);
    setDynamicColumns(columns);
  }, [fileData]);

  return (
    <div>
      <DataTable
        value={tableData}
        rowGroupMode="subheader"
        groupRowsBy="switchboard"
        sortMode="single"
        sortField="switchboard"
        rows={12}
        sortOrder={1}
        editMode={'cell'}
        paginator={true}
        loading={!tableData}
        emptyMessage={'Žádná data'}
        rowGroupHeaderTemplate={headerTemplate}
      >
        <Column
          field="switchboard"
          header="SB"
          body={switchboardBodyTemplate}
          className={'actionCol'}
          style={{ fontWeight: 'bold' }}
          bodyStyle={{ textAlign: 'center' }}
        />
        {dynamicColumns.map((col) => (
          <Column
            key={col.field}
            field={col.field}
            header={col.header}
            style={{ minWidth: '6rem' }}
            editor={(options) => cellEditor(options)}
            onCellEditComplete={onCellEditComplete}
            onCellEditInit={(e) => setEditingCell({ rowIndex: e.rowIndex, field: e.field })}
          />
        ))}
        <Column className={'actionCol'} body={actionBodyTemplate} style={{ width: '4rem' }} />
      </DataTable>

      <Dialog
        draggable={false}
        visible={dialogVisible}
        header="Přidat nový řádek"
        style={{ width: '30rem' }}
        onHide={() => setDialogVisible(false)}
      >
        <div className="flex flex-col gap-4 mb-5">
          <div className="flex flex-col">
            <label htmlFor="signal_name">Name</label>
            <InputText
              id="signal_name"
              value={newPin.signal_name}
              onChange={(e) => setNewPin({ ...newPin, signal_name: e.target.value })}
            />
          </div>
          <div className="flex flex-col">
            <label htmlFor="signal_type">Type</label>
            <InputText
              id="signal_type"
              value={newPin.signal_type}
              onChange={(e) => setNewPin({ ...newPin, signal_type: e.target.value })}
            />
          </div>
          <div className="flex flex-col">
            <label htmlFor="connector_name">Connector</label>
            <InputText
              id="connector_name"
              value={newPin.connector_name}
              onChange={(e) => setNewPin({ ...newPin, connector_name: e.target.value })}
            />
          </div>
          <div className="flex flex-col">
            <label htmlFor="line_number">Line</label>
            <InputText
              id="line_number"
              value={newPin.line_number}
              onChange={(e) => setNewPin({ ...newPin, line_number: e.target.value })}
            />
          </div>
        </div>
        <div className={'flex items-center justify-between'}>
          <Button label="Zrušit" onClick={() => setDialogVisible(false)} className={'btn-cancel'} />
          <Button label="Přidat" onClick={addNewPin} autoFocus={true} className={'btn-secondary'} />
        </div>
      </Dialog>
    </div>
  );
};

export default FileTable;
