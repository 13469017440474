import api from '@/api/api.ts';
import { PageLoader } from '@/components/PageLoader.tsx';
import { RefetchProvider } from '@/contexts/RefetchContext';
import { createFileRoute } from '@tanstack/react-router';
import { useNavigate } from '@tanstack/react-router';
import { Button } from 'primereact/button';
import { FileUpload, FileUploadHeaderTemplateOptions, FileUploadSelectEvent } from 'primereact/fileupload';
import { FC, useRef, useState } from 'react';

export const IndexPage: FC = () => {
  const [isLoaderVisible, setIsLoaderVisible] = useState(false);
  const fileUploadRef = useRef<FileUpload>(null);
  const iniUploadRef = useRef<FileUpload>(null);
  const [fileMap, setFileMap] = useState([]);
  const navigate = useNavigate();

  const itemTemplate = (inFile: object) => {
    const file = inFile as File;
    return (
      <div className="flex items-center justify-center">
        <span>{file.name}</span>
      </div>
    );
  };

  const emptyTemplate = () => {
    return (
      <div className="mt-2 w-full flex flex-col items-center justify-center gap-2">
        <span className={'text-[#bfc5d0] text-md'}>Přetáhněte soubor zde</span>
      </div>
    );
  };

  const templateSelect = (event: FileUploadSelectEvent, name: string) => {
    setFileMap((prevFileMap: any) => {
      const newFileMap = { ...prevFileMap };
      newFileMap[name] = event.files;
      return newFileMap;
    });
  };

  const headerTemplate = (options: FileUploadHeaderTemplateOptions) => {
    const { className, chooseButton } = options;

    return (
      <div className={className} style={{ backgroundColor: 'transparent', display: 'flex', alignItems: 'center' }}>
        {chooseButton}
      </div>
    );
  };

  const customUploader = async () => {
    const formData = new FormData();

    for (const [name, files] of Object.entries(fileMap)) {
      (files as File[]).forEach((file: any) => {
        formData.append(name, file);
      });
    }

    setIsLoaderVisible(true);

    try {
      const response = await api.post('upload', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      navigate({ to: '/file/$fileId', params: { fileId: response.data.uid } });
      setIsLoaderVisible(false);
    } catch (error) {
      console.error('Error uploading file', error);
      setIsLoaderVisible(false);
    }
  };

  const sendFiles = () => {
    if (fileUploadRef.current) {
      fileUploadRef.current.upload();
    }
    if (iniUploadRef.current) {
      iniUploadRef.current.upload();
    }

    customUploader();
  };

  return (
    <div className={'flex items-start gap-8'}>
      <RefetchProvider>
        <div className={'flex flex-col gap-12 w-full'}>
          <div className={'flex flex-col max-w-full w-[50rem] mx-auto'}>
            <FileUpload
              className={'w-full mb-4'}
              ref={fileUploadRef}
              name="pdf_file"
              chooseLabel={'Vybrat PDF soubor'}
              multiple={false}
              customUpload={true}
              maxFileSize={50000000}
              itemTemplate={itemTemplate}
              emptyTemplate={emptyTemplate}
              onSelect={(e) => templateSelect(e, 'pdf_file')}
              headerTemplate={headerTemplate}
            />
            <FileUpload
              className={'w-full'}
              ref={iniUploadRef}
              name="ini_file"
              chooseLabel={'Vybrat INI soubor'}
              multiple={false}
              customUpload={true}
              maxFileSize={50000000}
              itemTemplate={itemTemplate}
              emptyTemplate={emptyTemplate}
              onSelect={(e) => templateSelect(e, 'ini_file')}
              headerTemplate={headerTemplate}
            />
            <Button className={'btn-secondary flex mx-auto mt-6'} onClick={sendFiles}>
              Zpracovat soubory
            </Button>
          </div>
        </div>
      </RefetchProvider>
      <PageLoader visible={isLoaderVisible} />
    </div>
  );
};

export const Route = createFileRoute('/')({
  component: IndexPage,
});

export default IndexPage;
