import { ProgressSpinner } from 'primereact/progressspinner';
import { FC } from 'react';

interface PageLoaderProps {
  visible: boolean;
}

export const PageLoader: FC<PageLoaderProps> = ({ visible }) => {
  return (
    <div
      className={`absolute top-0 left-0 w-full h-full z-50 bg-white bg-opacity-50 backdrop-blur-sm flex justify-center items-center ${!visible ? 'hidden' : ''}`}
    >
      <ProgressSpinner
        style={{ width: '60px', height: '60px' }}
        strokeWidth="4"
        fill="var(--surface-ground)"
        animationDuration=".8s"
      />
    </div>
  );
};
