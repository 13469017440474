import { createFileRoute } from '@tanstack/react-router';
import { FC } from 'react';

export const ErrorPage: FC = () => {
  return (
    <div className="flex flex-col items-center justify-start h-screen">
      <h1 className="text-4xl font-bold mb-4">404 - Nenalezeno</h1>
      <p className="text-xl mb-8">Požadovaná stránka s daty nebyla nalezena.</p>
      <a href="/" className="text-blue-500 hover:text-blue-700 underline">
        Vrátit se zpět na úvodní stránku
      </a>
    </div>
  );
};

export const Route = createFileRoute('/error')({
  component: ErrorPage,
});

export default ErrorPage;
