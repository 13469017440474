import { useApiGet } from '@/api/apiRequests';
import { getFile } from '@/api/services/File';
import { get, set } from 'idb-keyval';
import { useCallback, useEffect, useRef, useState } from 'react';

export const useCachedFile = (fileId: string, page: number) => {
  const [cachedFile, setCachedFile] = useState<Blob | null>(null);
  const [filename, setFilename] = useState<string | null>(null);
  const [isFreshFileProcessed, setIsFreshFileProcessed] = useState(false);
  const [pdfError, setPdfError] = useState<string | null>(null);
  const [fileUrl, setFileUrl] = useState<string | null>(null);
  const fileUrlRef = useRef<string | null>(null);
  const [isCacheChecked, setIsCacheChecked] = useState(false);

  const {
    data: freshFile,
    isLoading,
    refetch,
    error: apiError,
  } = useApiGet(['fileUid', fileId], () => getFile(fileId), {
    refetchOnWindowFocus: false,
    retry: 0,
    enabled: false,
  });

  const loadCachedFile = useCallback(async () => {
    try {
      const cachedBlob = await get<Blob>(`file_${fileId}_${page}`);
      const cachedFilename = await get<string>(`filename_${fileId}_${page}`);

      if (cachedBlob && cachedBlob.size > 0) {
        setCachedFile(cachedBlob);
        setFilename(cachedFilename || '');
        return true;
      }
    } catch (err) {
      console.error('Error loading cached file:', err);
    }
    return false;
  }, [fileId]);

  const initializeFile = useCallback(async () => {
    if (!isCacheChecked) {
      const cacheLoaded = await loadCachedFile();
      setIsCacheChecked(true);
      if (!cacheLoaded) {
        refetch();
      }
    }
  }, [isCacheChecked, loadCachedFile, refetch]);

  useEffect(() => {
    initializeFile();
  }, [initializeFile]);

  useEffect(() => {
    const processFreshFile = async () => {
      if (freshFile && !isFreshFileProcessed) {
        try {
          const { content, content_type, filename: newFilename } = freshFile;
          const binaryContent = new Uint8Array(content);
          if (binaryContent.length === 0) {
            throw new Error('Decoded content is empty');
          }

          const blob = new Blob([binaryContent], { type: content_type });
          if (blob.size === 0) {
            throw new Error('Created blob is empty');
          }

          await set(`file_${fileId}_${page}`, blob);
          await set(`filename_${fileId}_${page}`, newFilename);

          setCachedFile(blob);
          setFilename(newFilename);
          setPdfError(null);
          setIsFreshFileProcessed(true);
        } catch (err) {
          console.error('Error processing fresh file:', err);
          setPdfError('Failed to process fetched file.');
        }
      }
    };

    if (freshFile) {
      processFreshFile();
    }
  }, [freshFile, fileId, isFreshFileProcessed]);

  useEffect(() => {
    if (apiError) {
      console.error('Error fetching file:', apiError);
      setPdfError('Error fetching file');
    }
  }, [apiError]);

  useEffect(() => {
    if (cachedFile) {
      const url = URL.createObjectURL(cachedFile);
      setFileUrl(url);
      fileUrlRef.current = url;
    }

    return () => {
      if (fileUrlRef.current) {
        URL.revokeObjectURL(fileUrlRef.current);
        fileUrlRef.current = null;
      }
    };
  }, [cachedFile]);

  const compareAndFetchFile = useCallback(async () => {
    if (!isLoading && !isFreshFileProcessed) {
      await initializeFile();
    }
  }, [isLoading, isFreshFileProcessed, initializeFile]);

  return {
    file: fileUrl,
    isLoading,
    isCacheChecked,
    compareAndFetchFile,
    filename,
    error: pdfError || apiError,
  };
};
