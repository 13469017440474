import api from '@/api/api.ts';
import axios from 'axios';
import { toast } from 'react-toastify';

export const getFileData = async (uid: string, options?: { cancelToken?: any }) => {
  try {
    const response = await api.get(`/process/${uid}`, options);
    return response.data;
  } catch (error: any) {
    if (axios.isCancel(error)) {
      // console.log('Request cancelled:', error.message);
    } else if (error?.response?.status !== 404) {
      if (error?.response?.data?.detail) {
        toast.error(`Error: ${error.response.data.detail}`);
      } else {
        toast.error(`Problém se zpracováním dat z modelu.`);
      }
    }
    throw error;
  }
};

export const getFile = async (uid: string) => {
  try {
    const response = await api.get(`/pdf/${uid}`, { responseType: 'arraybuffer' });
    return {
      content: response.data,
      content_type: response.headers['content-type'],
      filename: response.headers['content-disposition']?.split('filename=')[1]?.replace(/"/g, ''),
    };
  } catch (error: any) {
    if (error?.response?.status !== 404) {
      if (error?.response?.data?.detail) {
        toast.error(`Error: ${error.response.data.detail}`);
      } else {
        toast.error(`Problém se zpracováním PDF souboru.`);
      }
    }
    throw error;
  }
};

export const downloadFile = async (id: string, type: 'xlsx' | 'ini') => {
  try {
    const response = await api.get(`export/${type}/${id}`, {
      responseType: 'blob',
    });

    // Extract the filename from the Content-Disposition header
    const contentDisposition = response.headers['content-disposition'];
    const filenameMatch = contentDisposition && contentDisposition.match(/filename="?([^"]+)"?/);
    const filename = filenameMatch ? filenameMatch[1] : `file_${id}.xlsx`;

    // Create a URL for the blob and trigger a download
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
    link.parentNode?.removeChild(link);
  } catch (error: any) {
    if (error?.response?.data?.detail) {
      toast.error(`Error: ${error.response.data.detail}`);
    } else {
      toast.error(`Chyba při stažení '${type}' souboru.`);
    }
  }
};
