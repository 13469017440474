import { useApiGet } from '@/api/apiRequests';
import { getMeUser } from '@/api/services/getMeUser';
import { User } from '@/interfaces/User';
import { ReactNode, createContext, useContext, useEffect, useState } from 'react';
import { useAuth } from 'react-oidc-context';

interface UserContextType {
  currentUser: User | null;
  isUserLoading: boolean;
}

const UserContext = createContext<UserContextType | undefined>(undefined);

export const UserProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [currentUser, setCurrentUser] = useState<User | null>(null);
  const [isUserLoading, setIsUserLoading] = useState<boolean>(true);
  const auth = useAuth();

  const { data, isLoading, error, isError, refetch } = useApiGet(['user'], getMeUser, {
    enabled: false,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (auth.isAuthenticated && !currentUser) {
      refetch();
    }
  }, [auth.isAuthenticated, currentUser, refetch]);

  useEffect(() => {
    if (!isLoading && data) {
      setCurrentUser(data);
      setIsUserLoading(false);
    } else if (isError) {
      setIsUserLoading(false);
    }
  }, [data, isLoading, isError, error]);

  return <UserContext.Provider value={{ currentUser, isUserLoading }}>{children}</UserContext.Provider>;
};

export const useUser = () => {
  const context = useContext(UserContext);
  if (context === undefined) {
    throw new Error('useUser must be used within a UserProvider');
  }
  return context;
};
