import api from '@/api/api.ts';
import { downloadFile } from '@/api/services/File.ts';
import { PageLoader } from '@/components/PageLoader.tsx';
import FileTable from '@/components/fileTable.tsx';
import { useCachedFile } from '@/hooks/useCachedFile.ts';
import { useCachedFileData } from '@/hooks/useCachedFileData.ts';
import { drawOnCanvas } from '@/utils/drawCanvas.ts';
import { createFileRoute } from '@tanstack/react-router';
import { useNavigate } from '@tanstack/react-router';
import { PlusIcon } from 'lucide-react';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputNumber } from 'primereact/inputnumber';
import { InputText } from 'primereact/inputtext';
import { ProgressBar } from 'primereact/progressbar';
import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { toast } from 'react-toastify';

import 'react-pdf/dist/esm/Page/TextLayer.css';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';

pdfjs.GlobalWorkerOptions.workerSrc = new URL('pdfjs-dist/build/pdf.worker.min.mjs', import.meta.url).toString();

export const FilePage: FC = () => {
  const { fileId } = Route.useParams();
  const [numPages, setNumPages] = useState<number | null>(null);
  const [pageNumber, setPageNumber] = useState(1);
  const navigate = useNavigate();
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const pdfWidth = 1140;
  const [newSwitchboardDialogVisible, setNewSwitchboardDialogVisible] = useState(false);
  const [newSwitchboard, setNewSwitchboard] = useState({ identifier_name: '' });
  const [scale, setScale] = useState(1.0);
  const [zoomOrigin, setZoomOrigin] = useState({ x: 0, y: 0 });
  const [zoomed, setZoomed] = useState(false);
  const containerRef = useRef<any>(null);
  const [processingProgress, setProcessingProgress] = useState(0);
  const [isProcessingComplete, setIsProcessingComplete] = useState(false);
  const [loadedPages, setLoadedPages] = useState(0);
  const [isInitialDataLoaded, setIsInitialDataLoaded] = useState(false);
  const [isInitialStatusSet, setIsInitialStatusSet] = useState(false);

  const {
    file,
    isLoading: fileLoading,
    isCacheChecked: isCacheFileChecked,
    compareAndFetchFile,
  } = useCachedFile(fileId, pageNumber - 1);
  const {
    fileData,
    isLoading: fileDataLoading,
    compareAndFetch,
    compareAndFetchStatus,
    refetch,
    error: dataError,
  } = useCachedFileData(fileId);

  const setInitialProcessingStatus = useCallback(
    (pages: any[]) => {
      if (!pages || !fileData) return;

      const successfulPages = pages.filter((page: any) => page.status === 'success').length;
      const totalPages = fileData.page_count;
      const allPagesProcessed = successfulPages === totalPages;

      setLoadedPages(successfulPages);
      setProcessingProgress(Math.round((successfulPages / totalPages) * 100));

      // Only set isProcessingComplete to true if ALL pages are processed
      setIsProcessingComplete(allPagesProcessed);
      setIsInitialStatusSet(true);
    },
    [fileData]
  );

  // Handle 404 errors
  useEffect(() => {
    if (dataError?.response?.status === 404) {
      navigate({ to: '/error' });
    }
  }, [dataError, navigate]);

  useEffect(() => {
    const loadInitialData = async () => {
      if (!isInitialStatusSet && !fileDataLoading && fileData) {
        setInitialProcessingStatus(fileData.pages);
      }
    };

    loadInitialData();
  }, [isInitialStatusSet, fileDataLoading, fileData, setInitialProcessingStatus]);

  useEffect(() => {
    if (fileData && !isInitialDataLoaded) {
      setIsInitialDataLoaded(true);
      setInitialProcessingStatus(fileData.pages);
    }
  }, [fileData, isInitialDataLoaded, setInitialProcessingStatus]);

  useEffect(() => {
    const checkAndUpdateStatus = async () => {
      if (!isInitialStatusSet && !fileDataLoading) {
        compareAndFetch();
        if (fileData && fileData.pages) {
          setInitialProcessingStatus(fileData.pages);
          setIsInitialStatusSet(true);
        }
      }
    };

    checkAndUpdateStatus();
  }, [isInitialStatusSet, fileDataLoading, compareAndFetch, setInitialProcessingStatus, fileData]);

  // Trigger updates when file or metadata changes
  useEffect(() => {
    if (isCacheFileChecked && !file && !fileLoading) {
      compareAndFetchFile(); // Ensure file is loaded
    }
  }, [isCacheFileChecked, file, fileLoading, compareAndFetchFile]);

  const getCurrentPageData = useCallback(() => {
    return fileData?.pages?.find((page: any) => page.page_number === pageNumber - 1) || null;
  }, [fileData, pageNumber]);

  const clearCanvas = useCallback(() => {
    if (canvasRef.current) {
      const ctx = canvasRef.current.getContext('2d');
      if (ctx) {
        ctx.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
      }
    }
  }, []);

  // Draw data on canvas whenever fileData or pageNumber changes
  useEffect(() => {
    const pageData = getCurrentPageData();
    if (pageData && canvasRef.current) {
      if (pageData.status === 'success') {
        drawOnCanvas(pageData, pdfWidth, canvasRef);
      } else {
        clearCanvas(); // Clear the canvas if the page data is not successful
      }
    }
  }, [getCurrentPageData, clearCanvas, pdfWidth]);

  useEffect(() => {
    let intervalId: NodeJS.Timeout | null = null;

    const checkProcessingStatus = async () => {
      if (!fileData) return false;

      const updatedPages = await compareAndFetchStatus();
      if (updatedPages) {
        const successfulPages = updatedPages.filter((page: any) => page.status === 'success').length;
        const totalPages = fileData.page_count;
        const allPagesProcessed = successfulPages === totalPages;

        setLoadedPages(successfulPages);
        setProcessingProgress(Math.round((successfulPages / totalPages) * 100));

        // Only set isProcessingComplete to true if ALL pages are processed
        setIsProcessingComplete(allPagesProcessed);

        return allPagesProcessed;
      }
      return false;
    };

    const startInterval = () => {
      checkProcessingStatus();

      intervalId = setInterval(async () => {
        const allProcessed = await checkProcessingStatus();
        if (allProcessed) {
          clearInterval(intervalId!);
        }
      }, 15000);
    };

    if (fileData) {
      startInterval();
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [fileData, compareAndFetchStatus]);

  const handleResetZoom = useCallback(() => {
    setScale(1.0);
    setZoomed(false);
    setZoomOrigin({ x: 0, y: 0 });
    if (containerRef.current) {
      containerRef.current.scrollTop = 0;
      containerRef.current.scrollLeft = 0;
    }
  }, []);

  const handlePdfClick = useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      if (!containerRef.current) return;

      const container = containerRef.current;
      const rect = container.getBoundingClientRect();
      const x = event.clientX - rect.left + container.scrollLeft;
      const y = event.clientY - rect.top + container.scrollTop;

      if (zoomed) {
        // If already zoomed, reset to normal view
        handleResetZoom();
      } else {
        // If not zoomed, zoom in
        setScale(5.0);
        setZoomed(true);
        setZoomOrigin({ x, y });

        container.scrollLeft = x * 5 - container.offsetWidth / 2;
        container.scrollTop = y * 5 - container.offsetHeight / 2;
      }
    },
    [zoomed, handleResetZoom]
  );

  const onDocumentLoadSuccess = ({ numPages: total }: { numPages: number }) => {
    setNumPages(total);
  };

  const changePage = useCallback(
    async (newPage: number) => {
      if (newPage >= 1 && newPage <= (numPages || 1)) {
        setPageNumber(newPage);
        clearCanvas(); // Clear the canvas when changing pages

        // Check if the page data exists and has a 'success' status
        const currentPageData = fileData?.pages?.find((page: any) => page.page_number === newPage - 1);

        if (!currentPageData || currentPageData.status !== 'success') {
          compareAndFetch(newPage);
        }
      }
    },
    [numPages, compareAndFetch, fileData, clearCanvas]
  );

  const openNewSwitchboardDialog = () => {
    setNewSwitchboardDialogVisible(true);
  };

  const addNewSwitchboard = async () => {
    try {
      const response = await api.post(`add/switchboard/${fileData?.uid}/${pageNumber - 1}`, { ...newSwitchboard });
      if (response.status === 200) {
        toast.success('Nový switchboard úspěšně přidán.');
        setNewSwitchboardDialogVisible(false);
        setNewSwitchboard({ identifier_name: '' });
        await refetch();
        setPageNumber((prevPageNumber) => prevPageNumber);
      }
    } catch (error: any) {
      if (error?.response?.data?.detail) {
        toast.error(`Error: ${error.response.data.detail}`);
      } else {
        toast.error(`Chyba při ukládání switchboardu.`);
      }
    }
  };

  return (
    <>
      <div className={'flex justify-between items-center gap-2 w-full'}>
        <h1 className={'font-bold text-xl'}>Zobrazuji soubor: {fileData?.filename}</h1>
        {fileData && (
          <div className={'flex items-center gap-3'}>
            <Button className={'btn-secondary'} size={'small'} onClick={() => downloadFile(fileId, 'xlsx')}>
              Export XLSX
            </Button>
            <Button className={'btn-secondary'} size={'small'} onClick={() => downloadFile(fileId, 'ini')}>
              Export INI
            </Button>
          </div>
        )}
      </div>
      <div className={'flex flex-wrap items-start gap-4 justify-center w-full mt-8'}>
        <div className={'flex flex-col items-center gap-4'}>
          {fileData && (
            <div className={'flex items-center gap-4 w-full border p-4 bg-[#d4d7da] bg-opacity-15 border-[#d5d5d5]'}>
              <p className={'mb-0 inline-block flex-1'}>
                Zpracované stránky: <strong>{loadedPages}</strong> z <strong>{fileData.page_count}</strong>
              </p>
              <ProgressBar
                className={`flex-1 ${isProcessingComplete ? 'isFull' : ''}`}
                style={{ height: '1.35rem', width: '100%' }}
                value={processingProgress}
              />
            </div>
          )}
          <div className={`relative overflow-hidden max-w-full`} style={{ width: `${pdfWidth}px`, height: '806px' }}>
            <PageLoader visible={!fileData} />
            {file && (
              // biome-ignore lint/a11y/useKeyWithClickEvents: <explanation>
              <div
                ref={containerRef}
                style={{
                  overflow: 'hidden',
                  cursor: zoomed ? 'zoom-out' : 'zoom-in',
                  position: 'relative',
                }}
                onClick={handlePdfClick}
              >
                <div
                  style={{
                    transform: `scale(${scale})`,
                    transformOrigin: `${zoomOrigin.x}px ${zoomOrigin.y}px`,
                    transition: 'transform 0.3s ease-in-out', // Smooth zoom transition
                  }}
                >
                  <Document
                    file={file}
                    loading={<PageLoader visible={!fileData} />}
                    onLoadSuccess={onDocumentLoadSuccess}
                    onLoadError={(error) => console.error('PDF Load Error:', error)}
                  >
                    <div className={'relative'}>
                      <Page
                        pageNumber={pageNumber}
                        width={pdfWidth}
                        onLoadSuccess={(page) => {
                          // Ensure page dimensions are set
                          const { width, height } = page;
                          if (canvasRef.current) {
                            canvasRef.current.style.width = `${width}px`;
                            canvasRef.current.style.height = `${height}px`;
                            // Trigger redraw when Page is loaded
                            const pageData = getCurrentPageData();
                            if (pageData) {
                              drawOnCanvas(pageData, pdfWidth, canvasRef);
                            }
                          }
                        }}
                      >
                        <canvas
                          ref={canvasRef}
                          style={{ position: 'absolute', top: '0', left: '0', pointerEvents: 'none' }}
                        />
                      </Page>
                    </div>
                  </Document>
                </div>
              </div>
            )}
          </div>
          <div className={'page-controls'}>
            <Button className={'btn-blue'} onClick={() => changePage(pageNumber - 1)} disabled={pageNumber <= 1}>
              Předchozí
            </Button>
            <div className={'flex items-center gap-2'}>
              <InputNumber
                className={'numberInput'}
                name={'pageNumber'}
                value={pageNumber}
                max={numPages!}
                min={1}
                onChange={(e: any) => {
                  const newPage = Math.min(e.value, numPages!);
                  if (newPage !== pageNumber) {
                    changePage(newPage);
                  }
                }}
              />
              z {numPages}
            </div>
            <Button
              className={'btn-blue'}
              onClick={() => changePage(pageNumber + 1)}
              disabled={pageNumber >= (numPages || 1)}
            >
              Další
            </Button>
          </div>
        </div>

        <div className={'relative'}>
          <div className={'flex items-center justify-end gap-2 mb-1'}>
            <Button
              icon={<PlusIcon size={20} />}
              className={'p-button-text text-black text-xs'}
              size={'small'}
              iconPos={'right'}
              onClick={() => openNewSwitchboardDialog()}
            >
              Přidat switchboard
            </Button>
          </div>
          <FileTable fileData={getCurrentPageData} fileId={fileId} />
        </div>
      </div>

      <Dialog
        draggable={false}
        style={{ width: '30rem' }}
        header="Přidat nový switchboard"
        visible={newSwitchboardDialogVisible}
        onHide={() => setNewSwitchboardDialogVisible(false)}
      >
        <div className="flex flex-col gap-4 mb-5">
          <div className="flex flex-col">
            <label htmlFor="name">Název switchboardu</label>
            <InputText
              id="name"
              value={newSwitchboard.identifier_name}
              onChange={(e) => setNewSwitchboard({ ...newSwitchboard, identifier_name: e.target.value })}
            />
          </div>
        </div>
        <div className={'flex items-center justify-between'}>
          <Button label="Zrušit" onClick={() => setNewSwitchboardDialogVisible(false)} className={'btn-cancel'} />
          <Button label="Přidat" onClick={addNewSwitchboard} autoFocus={true} className={'btn-secondary'} />
        </div>
      </Dialog>
    </>
  );
};

export const Route = createFileRoute('/file/$fileId/')({
  component: FilePage,
});

export default FilePage;
