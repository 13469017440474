import { useApiGet } from '@/api/apiRequests';
import { getFileData } from '@/api/services/File.ts';
import axios, { AxiosError } from 'axios';
import { useCallback, useRef, useState } from 'react';

const TIMEOUT_DURATION = 5000;

export const useCachedFileData = (fileId: string) => {
  const cancelTokenSourceRef = useRef<any>(null);
  const [suppressError, setSuppressError] = useState(false);

  const {
    data: fileData,
    refetch,
    isLoading,
    error: originalError,
  } = useApiGet<any, AxiosError>(
    ['fileData', fileId],
    async () => {
      setSuppressError(false);
      if (cancelTokenSourceRef.current) {
        cancelTokenSourceRef.current.cancel('Operation canceled due to new request.');
      }

      cancelTokenSourceRef.current = axios.CancelToken.source();

      const timeoutId = setTimeout(() => {
        if (cancelTokenSourceRef.current) {
          cancelTokenSourceRef.current.cancel('Request timed out after 5 seconds');
          setSuppressError(true);
        }
      }, TIMEOUT_DURATION);

      try {
        return await getFileData(fileId, { cancelToken: cancelTokenSourceRef.current.token });
      } finally {
        clearTimeout(timeoutId);
      }
    },
    {
      refetchOnWindowFocus: false,
      retry: 0,
      enabled: false,
    }
  );

  // Custom error handler
  const error = suppressError ? null : originalError;

  const compareAndFetch = useCallback(
    (pageNumber?: number) => {
      if (
        !fileData ||
        (pageNumber &&
          !fileData.pages?.some((page: any) => page.status === 'success' && page.page_number === pageNumber - 1))
      ) {
        refetch();
      }
    },
    [fileData, refetch]
  );

  const compareAndFetchStatus = useCallback(async () => {
    await refetch();
    return fileData?.pages || null;
  }, [refetch, fileData]);

  return {
    fileData: fileData || null,
    isLoading,
    compareAndFetch,
    compareAndFetchStatus,
    error,
    refetch,
  };
};
