import { onSigninCallback, userManager } from '@/config/keycloak';
import { UserProvider } from '@/contexts/UserContext';
import { routeTree } from '@/routeTree.gen';
import { queryClient } from '@/utils/queryClient';
import { QueryClientProvider } from '@tanstack/react-query';
import { RouterProvider, createRouter } from '@tanstack/react-router';
import { PrimeReactProvider } from 'primereact/api';
import Tailwind from 'primereact/passthrough/tailwind';
import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { AuthProvider } from 'react-oidc-context';

import '@/styles/tailwind.css';
import '@/styles/index.css';

const router = createRouter({
  routeTree,
  context: { queryClient },
  defaultPreload: 'intent',
  defaultPreloadStaleTime: 0,
  defaultNotFoundComponent: () => <div>Page not found</div>,
});

declare module '@tanstack/react-router' {
  interface Register {
    router: typeof router;
  }
}

const primeValue = {
  pt: Tailwind,
};

createRoot(document.getElementById('root')!).render(
  <StrictMode>
    <AuthProvider userManager={userManager} onSigninCallback={onSigninCallback}>
      <QueryClientProvider client={queryClient}>
        <UserProvider>
          <PrimeReactProvider value={primeValue}>
            <RouterProvider router={router} />
          </PrimeReactProvider>
        </UserProvider>
      </QueryClientProvider>
    </AuthProvider>
  </StrictMode>
);
