import { ProtectedApp } from '@/components/protectedApp.tsx';
import { useUser } from '@/contexts/UserContext.tsx';
import { User } from '@/interfaces/User.ts';
import styled from '@emotion/styled';
import { Link, Outlet, createRootRoute } from '@tanstack/react-router';
import { LogOut } from 'lucide-react';
import { FC, useEffect, useState } from 'react';
import { useAuth } from 'react-oidc-context';
import { ToastContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.min.css';

const NavBar = styled.nav`
  width: 100%;
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 900;
  border-bottom: 3px solid #e52313;
  box-shadow: 0px 3px 13px -2px rgba(0, 0, 0, .12);
`;

const Main = styled.main`
  margin-bottom: 2rem;
  position: relative;
  padding: 2.5rem 1rem;
`;

const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const Footer = styled.footer`
  width: 100%;
  margin-top: auto;
  font-size: 0.725rem;
  text-align: center;
  padding: 1rem 2rem;
  color: #1b1531;
  opacity: .3;
`;

const App: FC = () => {
  const auth = useAuth();
  const { currentUser, isUserLoading } = useUser();
  const [user, setUser] = useState<User | null>(null);

  useEffect(() => {
    if (auth.isAuthenticated && currentUser) {
      setUser(currentUser);
    } else if (!auth.isLoading && !auth.isAuthenticated) {
      setUser(null);
    } else if (auth.isAuthenticated && isUserLoading) {
      // console.log('Authenticated, waiting for user data');
    }
  }, [auth.isAuthenticated, auth.isLoading, currentUser, isUserLoading]);

  return (
    <>
      <Content>
        <NavBar>
          <Link to={'/'}>
            <span className={'text-xl text-[#0056a4]'}>MSV IO</span>
          </Link>
          {auth.isAuthenticated && user && (
            <div className={'flex items-center gap-1 font-normal'}>
              <span>
                {user.given_name} {user.family_name}
              </span>
              <LogOut
                size={18}
                className={'ml-2 cursor-pointer transition-all text-[#8F8F8F] hover:text-primary'}
                onClick={() => auth.signoutRedirect()}
              />
            </div>
          )}
        </NavBar>
        <Main>
          <ProtectedApp>
            <Outlet />
          </ProtectedApp>
        </Main>
        <Footer>App version: {import.meta.env.VITE_APP_VERSION}</Footer>
        <ToastContainer position="bottom-right" autoClose={6000} newestOnTop={true} draggable={false} />
      </Content>
    </>
  );
};

export const Route = createRootRoute({
  component: App,
});
