import styled from '@emotion/styled';
import { Button } from 'primereact/button';
import React, { type ReactNode, useEffect, useState } from 'react';
import { hasAuthParams, useAuth } from 'react-oidc-context';

interface ProtectedAppProps {
  children: ReactNode;
}

const PageTitle = styled.h1`
  font-size: 2.5rem;
  text-align: center;
  margin-bottom: 2rem;
`;

const PageButton = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;
`;

export const ProtectedApp: React.FC<ProtectedAppProps> = (props) => {
  const { children } = props;
  const auth = useAuth();
  const [hasTriedSignin, setHasTriedSignin] = useState(false);

  useEffect(() => {
    if (!hasAuthParams() && !auth.isAuthenticated && !auth.activeNavigator && !auth.isLoading && !hasTriedSignin) {
      auth.signinRedirect();
      setHasTriedSignin(true);
    }
  }, [auth, hasTriedSignin]);

  switch (auth.activeNavigator) {
    case 'signinSilent':
      return <p className={'fixed bottom-4 right-4 text-xs text-[#bfc5d0]'}>Právě vás přihlašujeme...</p>;
    case 'signoutRedirect':
      return <p className={'fixed bottom-4 right-4 text-xs text-[#bfc5d0]'}>Právě vás odhlašujeme...</p>;
  }

  return (
    <>
      {auth.error ? (
        <>
          <PageTitle>Oops. Nastal nějaký problém s vaším přihlášením.</PageTitle>
          <PageButton>
            <Button className={'btn-blue'} onClick={() => auth.signinRedirect()}>
              Přihlásit se
            </Button>
          </PageButton>
        </>
      ) : auth.isLoading ? (
        <>
          <p className={'fixed bottom-4 right-4 text-xs text-[#bfc5d0]'}>Přihlašuji...</p>
        </>
      ) : auth.isAuthenticated ? (
        children
      ) : (
        <>
          <PageTitle>Vítejte zpět. Prosím, nejprve se přihlaste do svého účtu.</PageTitle>
          <PageButton>
            <Button className={'btn-btn-blue'} onClick={() => auth.signinRedirect()}>
              Přihlásit se
            </Button>
          </PageButton>
        </>
      )}
    </>
  );
};
